import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import paymentSuccessGif from '../../Assets/Business_section.png'
import SuccessIcon from '../../Assets/check.png'
import { Bars } from 'react-loader-spinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//These imports are for the reports download
import jsPDF from "jspdf";
import "jspdf-autotable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // bgcolor: "background.paper",
    bgcolor: "#FFFFFF"
};

const PaymentSuccess = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const [openBox, setOpenBox] = useState(true);
    const navigate = useNavigate();

    const [billingListShowLoader, setBillingListShowLoader] = useState(false);
    const [billingPrice, setBillingPrice] = useState("");
    const [billingPlanName, setBillingPlanName] = useState("");
    const [billingSuccessPayment, setBillingSuccessPayment] = useState("");
    const [billingTransactionId, setBillingTransactionId] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [billingPaymentType, setBillingPaymentType] = useState("");
    const [iso2Country, setIso2Country] = useState(""); // State to hold the correct ISO2 country code

    // getting the getpayment resposne status
    const [AllDataResponse, setAllDataResponse] = useState('')

    const SessionId = localStorage.getItem('SessionId');
    const UserUuid = localStorage.getItem("UserUuid");
    const BillingData = localStorage.getItem("BillingData");
    const BillingCountry = localStorage.getItem("BillingCountry");
    const countryName = localStorage.getItem("BillingCountryName");

    const BillingPlan = localStorage.getItem("BillingPlan");
    const BillingPayment = localStorage.getItem("BillingPrice");
    const BillingDays = localStorage.getItem("BillingDays");
    const BillingUID = localStorage.getItem("BillingUid");
    const PlanType = localStorage.getItem("PlanType")

    const countryData = require('country-data');




    useEffect(() => {
        // Retrieve the ISO2 country code based on the selected country
        const country = countryData.countries[BillingCountry];
        const iso2 = country ? country.alpha2 : "Unknown country code";
        setIso2Country(iso2);
    }, [BillingCountry]);






    const handleCreatePlan = (paymentDetailsOrder, iso2) => {
        const ICCID = localStorage.getItem('ICCID');

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "planname": BillingPlan,
            "user_uuid": UserUuid,
            "days": BillingDays,
            "country": countryName,
            "order": paymentDetailsOrder,
            "data": BillingData,
            "price": BillingPayment,
            "plan_type": PlanType
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/create_plan/${ICCID}/${BillingUID}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const showBillingData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "sessionId": SessionId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${REACT_APP_BASE_URL}/get_payment_details?session_id=${SessionId}`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setBillingListShowLoader(true);
            setBillingPrice(result.session_url.price);
            setBillingPlanName(result.session_url.plan_name);
            setBillingSuccessPayment(result.payment_details.status);
            setBillingTransactionId(result.payment_details.id);
            setBillingEmail(result.payment_details.billing_details.email);
            setBillingPaymentType(result.payment_details.payment_methods.type);
            setAllDataResponse(result.status);

            let iccid = localStorage.getItem('ICCID');
            if (!iccid) {
                const apiHeaders = new Headers();
                apiHeaders.append("Content-Type", "application/json");

                const country = countryData.countries[BillingCountry];
                const CountryName = country ? country.name : "Unknown country code";
                const iso2Country = country ? country.alpha2 : "";

                const apiRaw = JSON.stringify({
                    "tag": "",
                    "region": iso2Country.trim(),
                    "country": CountryName,
                    "days": BillingDays,
                    "data": BillingData
                });

                const apiRequestOptions = {
                    method: "POST",
                    headers: apiHeaders,
                    body: apiRaw,
                    redirect: "follow"
                };

                const esimResponse = await fetch(`${REACT_APP_BASE_URL}/create_esim?user_uuid=${UserUuid}`, apiRequestOptions);
                const esimResult = await esimResponse.json();

                iccid = esimResult.esim.iccid;
                localStorage.setItem("ICCID", iccid);
            }

            handleCreatePlan(result.payment_details.id, iccid);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        showBillingData();
    }, []);

    const generatePDF = () => {
        const report = new jsPDF("portrait", "mm", "a4");

        const data = [];
        const headers = ['Field', 'Value'];

        const tableData = document.querySelectorAll('.payment_detail p');
        tableData.forEach(row => {
            const field = row.childNodes[0].textContent.trim();
            const value = row.childNodes[1].textContent.trim();
            data.push([field, value]);
        });

        report.autoTable({
            head: [headers],
            body: data,
            theme: "striped",
            styles: { fontSize: 8 },
            margin: { top: 15 },
        });
        report.save("Payment_Success_Details.pdf");
    };

    const handleHomenavigate = () => {
        navigate('/home');
    }

    return (
        <>
            {/* <div className="PaymentSuccess"></div> */}

            <Modal open={openBox}>
                <Box sx={style} className="SuccessPaymentModalStyling">
                    <img src={paymentSuccessGif} className="success_gif" alt="Payment Success" />
                    {billingListShowLoader ? (
                        <div className="paymnet_main">
                            <div className="payment_detail" id="Table-Data">
                                <h2>Payment Successful    <img src={SuccessIcon} className='SuccessIcon' alt="Sucess icon" /></h2>

                                <hr />
                                {AllDataResponse === 1 &&

                                    <>
                                        <p>
                                            Transaction Id :
                                            <span> {billingTransactionId}</span>

                                        </p>
                                        <p>
                                            Payment Type :
                                            <span style={{ textTransform: 'capitalize' }}> {billingPaymentType} </span>
                                        </p>
                                        <p>
                                            Email ID :
                                            <span> {billingEmail} </span>
                                        </p>
                                    </>

                                }
                                <p>
                                    Package Name :
                                    <span> {billingPlanName}</span>
                                </p>
                                <p>
                                    Amount :
                                    <span> $ {billingPrice}</span>
                                </p>
                                <p>
                                    Status Payment :
                                    <span style={{ textTransform: 'capitalize' }}> {billingSuccessPayment}</span>
                                </p>
                                {/* <p >
                                    Country Code :
                                    <span> {iso2Country}</span>
                                </p> */}
                            </div>

                            <div style={{
                                display: "flex",
                                padding: "15px 0px",
                                width: '50%',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    style={{ color: "Green", transform: "scale(.9)" }}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={generatePDF}
                                >
                                    Print
                                </Button>

                                <Button
                                    style={{ color: "red", transform: "scale(.9)" }}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={handleHomenavigate}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div style={{
                            width: '100%',
                            height: '20vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Bars
                                height="30"
                                width="30"
                                color="#37BAE4"
                                visible={true}
                            />
                        </div>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default PaymentSuccess;
